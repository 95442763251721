.App {
	text-align: center;
	font-family: 'Cormorant Garamond', serif;
}

a {
	color: black;
}
a:link {
	text-decoration: none;
	color: black;
}
a:visited {
	text-decoration: none;
	color: black;
}
a:hover {
	text-decoration: none;
	color: #0072bb;
}
a:active {
	text-decoration: none;
	color: #0072bb;
}

.sticky {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9;
	height: 82px;
}

.top-header-hide {
	display: none;
	justify-content: center;
	background-color: #fff;
}
.top-header-show {
	display: flex;
	z-index: 9;
	justify-content: center;
	background-color: #fff;
}

.hide {
	display: none;
	justify-content: center;
	background-color: #fff;
}

.sticky .show {
	display: flex;
	justify-content: center;
	background-color: #fff;
}

.header_link {
	padding: 20px;
	background-color: transparent;
	color: black;
	font-size: 24px;
	margin-left: 20px;
	margin-right: 20px;
	border: none;
	border-bottom: 3px solid transparent;
	cursor: pointer;
	outline: none;
}

.header {
	display: flex;
	background-color: white;
}

.selected {
	border-bottom: 3px solid #0072bb;
	color: #0072bb;
}
